import { ButtonV3, TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import type { FocusEvent, ReactNode } from 'react';
import { type MessageKey } from 'types/LocaleMessageKey';

import { EventType } from '@/analytics/types';
import Motion from '@/app/components/Motion';

interface HeroCopyProps {
  readonly color?: 'light' | 'dark';
  readonly onHeroButtonFocus: (event: FocusEvent) => void;
  readonly labelKey: MessageKey;
  readonly copyKey?: MessageKey | undefined;
  readonly buttonVariant?: string | undefined;
  readonly buttonClassName?: string | undefined;
  readonly hideButton?: boolean | undefined;
  readonly icon?: ReactNode | undefined;
}

const HeroCopy = ({
  color = 'light',
  onHeroButtonFocus,
  labelKey,
  copyKey,
  buttonVariant = 'primary',
  buttonClassName,
  hideButton = false,
  icon,
}: HeroCopyProps): JSX.Element => {
  const t = useTranslations();
  const path = usePathname();

  const handleClick = async (): Promise<void> => {
    await window.ouraAnalytics.track(EventType.CTAClicked, {
      cta: 'shop_now',
      action: 'go_to_pdp',
      location: 'home hero',
      path,
    });
  };

  const handleFocus = (event: FocusEvent<HTMLButtonElement, Element>) => {
    onHeroButtonFocus(event);
  };

  return (
    <Motion
      data-cy="home-hero-copy"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, type: 'tween' }}
    >
      {Boolean(copyKey) && (
        <TypographyV3
          className="mb-6"
          Element="h2"
          variant="body-large"
          color={color}
          align="centerMdLeft"
        >
          {t(copyKey)}
        </TypographyV3>
      )}
      <div className="mb-8 w-full md:inline-block md:w-fit lg:mb-12">
        <ButtonV3
          className={cx(
            'w-full',
            {
              hidden: hideButton,
            },
            buttonClassName,
          )}
          variant={buttonVariant}
          href="/product/rings"
          onClick={handleClick}
          onFocus={handleFocus}
        >
          {t(labelKey)}
        </ButtonV3>
        {icon}
      </div>
    </Motion>
  );
};

export default HeroCopy;
