import { InverseHeader } from '@jouzen/ecom-components';
import { useInView } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import HomeBottomHero from './HomeBottomHero';
import HomeTopHero from './HomeTopHero';

const Gen3Hero = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const isTopHeroInView = useInView(topRef);
  const [topHeroHeight, setTopHeroHeight] = useState<number>(0);
  const isBottomHeroInView = useInView(bottomRef);

  useEffect(() => {
    if (!topRef.current) return;
    const getBoundingClientRect = topRef.current.getBoundingClientRect();
    setTopHeroHeight(getBoundingClientRect.height);
  }, [setTopHeroHeight]);

  return (
    <>
      <InverseHeader enter margin="0% 0% 100% 0%">
        <HomeTopHero ref={topRef} isBottomHeroInView={isBottomHeroInView} />
      </InverseHeader>
      <HomeBottomHero
        ref={bottomRef}
        isTopHeroInView={isTopHeroInView}
        topHeroHeight={topHeroHeight}
      />
    </>
  );
};

export default Gen3Hero;
