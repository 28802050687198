import type { ReactNode } from 'react';

import Motion from '@/app/components/Motion';

const tValue = {
  em: function em(chunks: ReactNode) {
    return (
      <Motion
        className="font-serif italic leading-normal xl:mt-[10vh] xl:self-end xl:leading-normal"
        el="em"
        initial={{
          opacity: 0,
          translateY: 25,
          filter: 'blur(5px)',
          background: 'inherit',
        }}
        animate={{ opacity: 1, translateY: 0, filter: 'none' }}
        transition={{ duration: 2, type: 'tween', delay: 1.25 }}
      >
        {chunks}
      </Motion>
    );
  },
};

export default tValue;
