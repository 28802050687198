import {
  HsaFsaInlineLogo,
  InverseHeader,
  OuraRing4Logo,
  TypographyV3,
} from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import { memo, type ReactNode } from 'react';
import { useExperiment, useGate } from 'statsig-react';

import Image from '@/app/components/Image';
import Motion from '@/app/components/Motion';
import Video from '@/app/components/Video';

import HomeHero from './HomeHero';

const gen4RichText = {
  em: function em(chunks: ReactNode) {
    return (
      <Motion
        className="mt-2 font-serif italic leading-tight md:mt-4"
        el="em"
        initial={{
          opacity: 0,
          translateY: 25,
          filter: 'blur(5px)',
          background: 'inherit',
        }}
        animate={{ opacity: 1, translateY: 0, filter: 'none' }}
        transition={{ duration: 2, type: 'tween', delay: 1.25 }}
      >
        {chunks}
      </Motion>
    );
  },
};

const Gen4Hero = () => {
  const t = useTranslations();
  const { config } = useExperiment('or4_hero');
  const isVideo = config.get<boolean>('isVideo', false);
  const showHsaFsaEligibleIcon = useGate('usa_only').value;

  return (
    <InverseHeader>
      <HomeHero
        overlayPosition="absolute"
        cta={{
          label: 'gen4_hero_cta',
          buttonVariant: 'secondary-dark',
          buttonClassName: 'bg-[#1E2327] hover:bg-[#4A4741] font-bold',
          hideButton: false,
          className: 'w-full',
          icon: showHsaFsaEligibleIcon ? (
            <TypographyV3
              className="mt-2 flex flex-row items-center justify-center gap-1 text-white"
              data-cy="hsa-fsa-icon"
              weight="bold"
            >
              {t.rich('hsa_fsa_eligible_line_item', {
                logo: () => (
                  <HsaFsaInlineLogo className="shrink-0" color="white" />
                ),
              })}
            </TypographyV3>
          ) : undefined,
        }}
        TopSlot={isVideo ? <VideoHero /> : <StaticHero />}
        TitleSlot={
          <div className="mt-40 md:mt-[27.5vh] md:max-w-[540px] lg:max-w-[46%]">
            <span className="mb-2 flex flex-row items-center justify-center md:mb-5 md:justify-start">
              <OuraRing4Logo />
            </span>
            <TypographyV3
              Element="h1"
              variant="h1"
              className="flex flex-col items-center bg-gradient-to-br from-[#1E2427] to-[#AF751B] bg-clip-text font-medium leading-none text-transparent sm:text-4xl md:items-start md:text-6xl lg:text-[6.667vw] de:lg:text-[5vw] xxl:text-8xl de:xxl:text-7xl"
              color="light"
            >
              {t.rich('sleeker_smarter_ring_title', gen4RichText)}
            </TypographyV3>
          </div>
        }
      />
    </InverseHeader>
  );
};

const StaticHero = () => {
  const t = useTranslations();
  return (
    <>
      <Motion
        className="relative z-10 h-[calc(100svh-50px)] md:hidden"
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 1.1 }}
        transition={{ duration: 1.5, type: 'tween' }}
      >
        <Image
          alt={t('or4_home_page_hero_alt')}
          src={'/blue-sky/home-hero/or4-mobile-alt-1'}
          format="png"
          className="object-cover"
          fill
          sizes="100vw"
          priority={true}
        />
      </Motion>
      <Motion
        className="relative z-10 hidden h-full md:block"
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 1.1 }}
        transition={{ duration: 1.5, type: 'tween' }}
      >
        <Image
          alt={t('or4_home_page_hero_alt')}
          src={t('hero_top_image_gen4_desktop')}
          className="object-cover"
          fill
          sizes="100vw"
          priority={true}
        />
      </Motion>
    </>
  );
};

const VideoHero = memo(function VideoHero() {
  return (
    <>
      <Video
        className="relative hidden h-screen min-h-[50em] w-full object-cover md:block"
        autoPlay
        contentTitle="pop_hero"
        location="pop_hero"
        type="video/mp4"
        muted
        poster="or4-home-hero-desktop-fallback.png"
        playVideo
        preload="auto"
        priority
        controls={false}
        playsInline
        src="gen4-hero-desktop-tony-2.mp4"
      />
      <Video
        className="relative h-[calc(100svh-50px)] w-full object-cover md:hidden"
        autoPlay
        contentTitle="pop_hero"
        location="pop_hero"
        type="video/mp4"
        muted
        poster="or4-home-hero-mobile-fallback.png"
        playVideo
        preload="auto"
        priority
        controls={false}
        playsInline
        src="gen4-hero-mobile-final.mp4"
      />
    </>
  );
});

export default Gen4Hero;
