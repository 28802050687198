import { TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import type { FocusEvent, Ref } from 'react';
import { forwardRef } from 'react';

import Image from '@/app/components/Image';
import Motion from '@/app/components/Motion';

import HomeHero from './HomeHero';
import HomeHeroShopChiclet from './HomeHeroShopChiclet';
import tValue from './HomeHeroTValue';

interface HomeTopHeroProps {
  readonly isBottomHeroInView: boolean;
}

const onFocus = (event: FocusEvent) => {
  event.preventDefault();
  event.stopPropagation();
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const HomeTopHero = forwardRef(function HomeTopHero(
  { isBottomHeroInView }: HomeTopHeroProps,
  ref: Ref<HTMLDivElement>,
): JSX.Element {
  const t = useTranslations();
  return (
    <HomeHero
      data-cy="home-top-hero"
      ref={ref}
      overlayPosition={isBottomHeroInView ? 'fixed' : undefined}
      TopSlot={
        <>
          <Motion
            className="relative z-10 h-full md:hidden"
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1.5, type: 'tween' }}
          >
            <Image
              alt={t('hero_top_img_alt')}
              src={t('hero_top_image_mobile')}
              className="object-cover"
              fill
              sizes="100vw"
              priority={true}
            />
          </Motion>
          <Motion
            className="relative z-10 hidden h-full md:block"
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1.5, type: 'tween' }}
          >
            <Image
              alt={t('hero_top_img_alt')}
              src={t('hero_top_image_desktop')}
              className="object-cover"
              fill
              sizes="100vw"
              priority={true}
            />
          </Motion>
        </>
      }
      cta={{ label: 'shop', copy: 'home_hero_copy', hideButton: true }}
      BottomSlot={<HomeHeroShopChiclet onHeroButtonFocus={onFocus} />}
      TitleSlot={
        <TypographyV3
          Element="h1"
          variant="h1"
          className={cx(
            'flex flex-col items-center pt-[22vh]',
            'md:items-start md:pt-40',
            'xl:w-11/12 xl:pt-[22vh]',
          )}
          color="light"
        >
          {t.rich('revolutionary_smart_ring_title', tValue)}
        </TypographyV3>
      }
    />
  );
});

export default HomeTopHero;
