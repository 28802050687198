import { RadialGradient, TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import {
  useAnimationControls,
  useInView,
  useReducedMotion,
  useScroll,
  useTransform,
} from 'framer-motion';
import { useTranslations } from 'next-intl';
import {
  type FocusEvent,
  forwardRef,
  type Ref,
  useEffect,
  useRef,
} from 'react';

import Image from '@/app/components/Image';
import Motion from '@/app/components/Motion';

import HomeHero from './HomeHero';
import HomeHeroShopChiclet from './HomeHeroShopChiclet';
import tValue from './HomeHeroTValue';

interface HomeBottomHeroProps {
  readonly isTopHeroInView: boolean;
  readonly topHeroHeight: number;
}

const HomeBottomHero = forwardRef(function HomeBottomHero(
  { isTopHeroInView, topHeroHeight }: HomeBottomHeroProps,
  ref: Ref<HTMLDivElement>,
): JSX.Element {
  const shouldReduceMotion = useReducedMotion();
  const t = useTranslations();
  const controls = useAnimationControls();
  const mounted = useRef(false);
  const motionRef = useRef(null);
  const isInView = useInView(motionRef);
  const target = useRef(null);
  const { scrollYProgress } = useScroll({
    target,
    offset: ['start start', 'end start'],
    layoutEffect: false,
  });
  const parallaxY = useTransform(scrollYProgress, [0, 1], ['0%', '15%']);

  useEffect(() => {
    const isMounted = mounted.current;
    const start = async () => {
      await controls.start({ opacity: 1, scale: 1 });
    };
    const end = async () => {
      await controls.start({ opacity: 1, scale: 1.05 });
    };
    if (isInView && isMounted) {
      if (!isTopHeroInView) {
        start().catch(() => {
          // do nothing
        });
      } else {
        end().catch(() => {
          // do nothing
        });
      }
    }

    return () => {
      controls.stop();
    };
  }, [isTopHeroInView, controls, isInView]);

  useEffect(() => {
    const isMounted = mounted.current;
    if (!isMounted && isInView) {
      mounted.current = true;
    }
  }, [isInView]);

  const handleHeroButtonFocus = (event: FocusEvent) => {
    event.preventDefault();
    event.stopPropagation();
    window.scrollTo({
      top: topHeroHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Motion
      ref={motionRef}
      className={cx(
        'sticky bottom-0 h-lvh bg-sandstone-200 lg:min-h-[50vw] lg:pb-4',
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, type: 'tween', delay: 1 }}
    >
      <div ref={target} className="h-full">
        <HomeHero
          onFocus={handleHeroButtonFocus}
          data-cy="home-bottom-hero"
          ref={ref}
          cta={{
            label: 'shop',
            copy: 'home_hero_copy',
            copyVariant: 'dark',
            hideButton: true,
          }}
          TopSlot={
            <>
              <Motion
                className="relative z-10 h-full md:hidden"
                style={{
                  y: shouldReduceMotion ? 0 : parallaxY,
                }}
                initial={{ opacity: 0, scale: 1.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.5, type: 'tween' }}
              >
                <Image
                  alt={t('hero_bottom_img_alt')}
                  src={t('hero_bottom_image_mobile')}
                  className="object-cover"
                  fill
                  sizes="100vw"
                  priority={true}
                />
              </Motion>
              <Motion
                className="relative z-10 hidden h-full md:block"
                style={{ y: shouldReduceMotion ? 0 : parallaxY }}
                initial={{ opacity: 0, scale: 1.1 }}
                animate={controls}
                transition={{ duration: 1.5, type: 'tween' }}
              >
                <Image
                  alt={t('hero_bottom_img_alt')}
                  src={t('hero_bottom_image_desktop')}
                  className="object-cover"
                  fill
                  sizes="100vw"
                  priority={true}
                />
              </Motion>
              <RadialGradient
                hex="#D7D6D4"
                id="home_hero_mobile_gradient"
                opacity={1}
                position={[50, 25]}
                className="pointer-events-none z-20 md:hidden"
                shape="circle"
                size={50}
              />
            </>
          }
          BottomSlot={
            <HomeHeroShopChiclet onHeroButtonFocus={handleHeroButtonFocus} />
          }
          TitleSlot={
            <div role="presentation">
              <TypographyV3
                Element="h2"
                variant="h1"
                className={cx(
                  'flex flex-col items-center pt-[22vh]',
                  'md:items-start md:pt-40',
                  'xl:w-11/12 xl:pt-[22vh]',
                )}
                color="dark"
              >
                {t.rich('revolutionary_smart_ring_title', tValue)}
              </TypographyV3>
            </div>
          }
        />
      </div>
    </Motion>
  );
});

export default HomeBottomHero;
