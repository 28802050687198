import { useTranslations } from 'next-intl';
import { type SourceHTMLAttributes } from 'react';

import imgixLoader from '../loaders/imgixLoader';
import type { VideoProps } from '../types';

type UseVideoSourcesProps = Partial<
  Pick<
    VideoProps,
    'loader' | 'localizedSrc' | 'localizedSrcSet' | 'src' | 'srcSet' | 'type'
  >
>;

const useVideoSources = ({
  loader = (videoSrc) => imgixLoader(videoSrc),
  localizedSrc,
  localizedSrcSet,
  src,
  srcSet,
  type,
}: UseVideoSourcesProps): SourceHTMLAttributes<HTMLSourceElement>[] => {
  const t = useTranslations();
  let sources: SourceHTMLAttributes<HTMLSourceElement>[] = [];

  if (localizedSrcSet) {
    sources = localizedSrcSet.map(({ src: _src, ...source }) => ({
      ...source,
      src: t(_src),
      type,
    }));
  }

  if (localizedSrc) {
    sources = [
      {
        src: t(localizedSrc),
        type,
      },
    ];
  }

  if (srcSet) {
    sources = srcSet;
  }

  if (src) {
    sources = [
      {
        src,
        type,
      },
    ];
  }

  return sources.map((source) => ({
    type, // Use type prop as default value for type attr
    ...source,
    src: loader(source.src ?? ''),
  }));
};

export default useVideoSources;
