import { GridItem, LayoutGrid } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import {
  type FocusEvent,
  forwardRef,
  type HTMLAttributes,
  type ReactNode,
  type Ref,
} from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

import Motion from '@/app/components/Motion';

import HeroCopy from './HomeHeroCopy';

interface Props extends HTMLAttributes<HTMLElement> {
  readonly onFocus?: (event: FocusEvent) => void;
  readonly overlayPosition?: 'fixed' | 'absolute' | undefined;
  readonly cta: {
    label: MessageKey;
    copy?: MessageKey;
    buttonVariant?: string;
    buttonClassName?: string | undefined;
    copyVariant?: 'light' | 'dark';
    hideButton?: boolean;
    className?: string | undefined;
    icon?: ReactNode | undefined;
  };
  readonly TitleSlot?: ReactNode;
  readonly BottomSlot?: ReactNode;
  readonly TopSlot?: ReactNode;
}

const onHeroButtonFocus = (event: FocusEvent) => {
  event.preventDefault();
  event.stopPropagation();
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const HomeHero = forwardRef(function HomeHero(
  {
    overlayPosition,
    cta,
    TitleSlot,
    BottomSlot,
    TopSlot,
    onFocus = onHeroButtonFocus,
    ...props
  }: Props,
  ref: Ref<HTMLElement>,
) {
  return (
    <section
      className="h-svh min-h-[50em] bg-sandstone-200 lg:px-5 lg:pb-4"
      ref={ref}
      {...props}
    >
      <LayoutGrid className="h-full">
        <GridItem
          className="h-full overflow-hidden lg:rounded-b-xl"
          colEnd={{ sm: 'full' }}
          colStart={{ sm: 'full' }}
          rowEnd={{ sm: 2 }}
          rowStart={{ sm: 1 }}
        >
          {TopSlot}
        </GridItem>
        <GridItem
          className="z-20 clip-polygon"
          colEnd={{ sm: 'full' }}
          colStart={{ sm: 'full' }}
          rowEnd={{ sm: 2 }}
          rowStart={{ sm: 1 }}
        >
          <div
            className={cx('left-0 top-0 h-svh w-full', {
              fixed: overlayPosition === 'fixed',
              absolute: overlayPosition === 'absolute',
            })}
          >
            <LayoutGrid
              className="h-full content-between lg:px-5 lg:pb-4"
              rowGap={0}
            >
              <GridItem colEnd={{ sm: 'main' }} colStart={{ sm: 'main' }}>
                <Motion
                  initial={{
                    opacity: 0,
                    translateY: 25,
                    filter: 'blur(5px)',
                  }}
                  animate={{ opacity: 1, translateY: 0, filter: 'blur(0)' }}
                  transition={{ duration: 1.25, type: 'tween', delay: 1 }}
                >
                  {TitleSlot}
                </Motion>
              </GridItem>
              <GridItem
                className="hidden md:block"
                colEnd={{ md: 10, lg: 10, xl: 8 }}
                colStart={{ md: 'main', lg: 'main', xl: 'main' }}
              >
                <Motion
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 2, type: 'tween', delay: 1.25 }}
                >
                  <HeroCopy
                    color={cta.copyVariant ?? 'light'}
                    hideButton={cta.hideButton}
                    copyKey={cta.copy}
                    labelKey={cta.label}
                    buttonVariant={cta.buttonVariant}
                    buttonClassName={cta.buttonClassName}
                    onHeroButtonFocus={onFocus}
                    icon={cta.icon}
                  />
                </Motion>
              </GridItem>
              <GridItem
                className="flex flex-col items-center self-end"
                colEnd={{ sm: 'main', md: 'main', lg: 'main', xl: 'main' }}
                colStart={{ sm: 'main', md: 15, lg: 15, xl: 18 }}
              >
                <Motion
                  className="w-full md:hidden"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 2, type: 'tween', delay: 1.25 }}
                >
                  <HeroCopy
                    color={cta.copyVariant ?? 'light'}
                    hideButton={cta.hideButton}
                    copyKey={cta.copy}
                    labelKey={cta.label}
                    buttonVariant={cta.buttonVariant}
                    buttonClassName={cta.buttonClassName}
                    onHeroButtonFocus={onFocus}
                    icon={cta.icon}
                  />
                </Motion>
                {BottomSlot}
              </GridItem>
            </LayoutGrid>
          </div>
        </GridItem>
      </LayoutGrid>
    </section>
  );
});

export default HomeHero;
