import { VIDEO_FORMATS } from '../const';
import type { VideoFormat } from '../types';

/**
 * getSrcUrl constructs a video source url from the file name
 * (and an optional format).
 *
 * It will additionally strip the src URL of common prefixes used in this codebase
 * that will not work with the updated IMGIX source.
 *
 * URLs in IMGIX should be relative without a leading slash.
 *
 * For example:
 *   product/my-video.mp4
 *   home-page/my-other-video.mp4
 */

const getSrcUrl = (src: string, defaultFormat: VideoFormat = 'mp4'): string => {
  if (!src) return '';

  // remove the leading slash
  const path = src.startsWith('/') ? src.substring(1) : src;

  // ensure we have the file format extension
  const [last] = path.split('.').slice(-1);
  const includesFormat = VIDEO_FORMATS.includes(last as VideoFormat);

  return includesFormat ? path : `${path}.${defaultFormat}`;
};

export default getSrcUrl;
