import { usePathname } from 'next/navigation';
import type { FocusEvent, MouseEventHandler } from 'react';
import { useConfig } from 'statsig-react';
import type { MessageKey } from 'types/LocaleMessageKey';

import { EventType } from '@/analytics/types';
import { type ImageFormat } from '@/app/components/Image';
import LandingPageHeroChiclet from '@/app/components/LandingPage/LandingPageHero/LandingPageHeroChiclet';

interface RenderData {
  additionalSegmentProperties: {
    cta: string;
    location: string;
    action: string;
  };
  ctaLocaleKey: MessageKey;
  image: {
    altKey: MessageKey;
    src: string;
    format: ImageFormat;
  };
  localeKey: MessageKey;
  target: string;
  rel: string;
  url: string;
}

interface HomeHeroShopChicletProps {
  readonly onHeroButtonFocus: (event: FocusEvent) => void;
  readonly className?: string | undefined;
}

const HomeHeroShopChiclet = ({
  onHeroButtonFocus,
  className,
}: HomeHeroShopChicletProps): JSX.Element | null => {
  const path = usePathname();
  const { config } = useConfig('home_hero_chiclet');
  const activeChicletConfig = Object.keys(config.value).length > 0;
  const value = config.value as unknown as RenderData;
  const renderData = activeChicletConfig && value;

  if (!renderData) return null;

  const handleClick: MouseEventHandler<HTMLDivElement> = () => {
    const { action, cta, location } = renderData.additionalSegmentProperties;
    void window.ouraAnalytics.track(EventType.LinkClicked, {
      cta,
      action,
      location,
      path,
    });
  };

  return (
    <LandingPageHeroChiclet
      className={className}
      cta={{
        href: renderData.url,
        label: renderData.ctaLocaleKey,
        rel: renderData.rel,
        target: renderData.target,
      }}
      data-cy="home-hero-shop-chiclet"
      image={{
        format: renderData.image.format,
        localizedAlt: renderData.image.altKey,
        src: renderData.image.src,
      }}
      motionProps={{
        transition: { delay: 1, duration: 0.25 },
      }}
      onClick={handleClick}
      onFocusWithin={onHeroButtonFocus}
      title={renderData.localeKey}
    />
  );
};

export default HomeHeroShopChiclet;
