/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import noop from 'lodash/noop';
import Link, { type LinkProps } from 'next/link';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import type {
  AnchorHTMLAttributes,
  ComponentPropsWithoutRef,
  FocusEvent,
  MouseEventHandler,
} from 'react';
import { useFocusWithin } from 'react-aria';
import type { MessageKey } from 'types/LocaleMessageKey';

import BackdropBlur from '@/app/components/BackdropBlur';
import type { ImageProps } from '@/app/components/Image';
import Image from '@/app/components/Image';
import type { MotionProps } from '@/app/components/Motion';
import Motion from '@/app/components/Motion';

type LandingPageHeroChicletCta = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof LinkProps
> &
  LinkProps & {
    href: string;
    label: MessageKey;
  };

export interface LandingPageHeroChicletProps
  extends ComponentPropsWithoutRef<'div'> {
  readonly cta: LandingPageHeroChicletCta;
  readonly image: Pick<
    ImageProps,
    'alt' | 'format' | 'localizedAlt' | 'localizedSrc' | 'src'
  >;
  readonly motionProps?: MotionProps;
  readonly onFocusWithin?: (e: FocusEvent) => void;
  readonly title: MessageKey;
}

const LandingPageHeroChiclet = ({
  cta,
  image,
  motionProps = {},
  onClick,
  onFocusWithin = noop,
  title,
  ...props
}: LandingPageHeroChicletProps): JSX.Element => {
  const t = useTranslations();
  const router = useRouter();
  const { focusWithinProps } = useFocusWithin({
    onFocusWithin,
  });

  const _motionProps: MotionProps = {
    ...motionProps,
    animate: { opacity: 1 },
    initial: { opacity: 0 },
    transition: {
      duration: 1,
      ...motionProps.transition,
      type: 'just',
    },
  };

  const { label: ctaLabel, ...ctaProps } = cta;

  const handleWrapperClick: MouseEventHandler<HTMLDivElement> = (e) => {
    onClick?.(e);
    router.push(ctaProps.href);
  };

  return (
    <div
      data-cy="landing-page-hero-chiclet"
      {...focusWithinProps}
      {...props}
      className={cx(
        'mb-6 inline-block w-full max-w-[360px] cursor-pointer maxmd:mx-auto',
        props.className,
      )}
      onClick={handleWrapperClick}
    >
      <div className="relative z-100 p-1">
        <BackdropBlur
          {..._motionProps}
          color={{ sm: 'dark', lg: 'dark' }}
          opacity={{ sm: 0.4, lg: 0.4 }}
          variant="chiclet"
        />
        <Motion className="relative flex" {..._motionProps}>
          <div className="relative aspect-3/2 w-44 overflow-hidden rounded-xl px-20">
            <Image className="object-cover" fill sizes="200px" {...image} />
          </div>
          <div className="flex flex-col justify-start px-4 pb-4 pt-3 lg:pl-6 lg:pr-7">
            <TypographyV3
              className="mb-3 leading-snug"
              color="light"
              variant="body-small"
            >
              {t.rich(title)}
            </TypographyV3>
            <Link className="leading-[0px]" {...ctaProps}>
              <TypographyV3
                className="leading-none"
                color="light"
                Element="span"
                variant="body-small"
                weight="bold"
              >
                {t(ctaLabel)}
              </TypographyV3>
            </Link>
          </div>
        </Motion>
      </div>
    </div>
  );
};

export default LandingPageHeroChiclet;
